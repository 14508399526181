<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A schematic for the reaction Williamson ether synthesis between 2-naphthol (after
        deprotonation from NaOH) and benzyl bromide is shown below. Which of the options below the
        scheme represent the correct curved arrow notation for the
        <stemble-latex content="$\text{S}_\text{N}2$" />
        mechanism for this reaction?
      </p>

      <p class="pl-6 mb-5">
        <v-img :src="image1Name" style="max-width: 609px" />
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0">
        <v-radio v-for="option in options" :key="option.value" :value="option.value" class="my-3">
          <template #label>
            <v-img :src="option.img" :style="option.width" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI51LB_A4Q2',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      items: [
        {img: '/img/assignments/uci51LB_A4Q2_optionA.png', value: 'a', width: 'max-width: 255px'},
        {img: '/img/assignments/uci51LB_A4Q2_optionB.png', value: 'b', width: 'max-width: 255px'},
        {img: '/img/assignments/uci51LB_A4Q2_optionC.png', value: 'c', width: 'max-width: 255px'},
        {img: '/img/assignments/uci51LB_A4Q2_optionD.png', value: 'd', width: 'max-width: 255px'},
      ],
    };
  },
  computed: {
    image1Name() {
      return '/img/assignments/uci51LB_A4Q2_main.png';
    },
    options() {
      return seededShuffle(this.items, this.seed);
    },
    seed() {
      return this.$inertia.getSeed();
    },
  },
};
</script>
<style scoped></style>
